import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Withdraw from "./Withdraw";
import Coin from "./Coin";
import Clan from "./Clan";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/withdraw" element={<Withdraw />} />
      <Route path="/coin" element={<Coin />} />
      <Route path="/clan" element={<Clan />} />
    </Routes>
  </BrowserRouter>
);
